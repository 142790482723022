<template>
  <div class="login-screen" v-if="!authenticated">
    <div class="login-box">
      <p v-if="!loggedIn" class="login-box-welcome">Welcome</p>
      <p v-else-if="!authenticated" class="login-box-factor">Two-Factor Authentication</p>
      <hr>
      <div class="credentials-animate">
        <div class="credentials-login">
          <label for="email">Email:</label>
          <input id="email" type="text" value="loremipsum@libertycreativesolutions.com">
          <label for="password">Password:</label>
          <input id="password" type="password" value="somepassword">
          <p class="or-sign-in-with">Or Sign in with</p>
          <div class="single-sign-on">
            <img src="./assets/google-sign-on.png" alt="Google Sign On">
            <img src="./assets/microsoft-logo.png" alt="Microsoft Sign On">
            <img src="./assets/okta-logo.png" alt="Okta Sign On">
          </div>
        </div>
        <div class="credentials-authenticate">
          <label for="authCode">Enter your 6 digit code</label>
          <input id="authCode" type="text">
        </div>
      </div>
      <div class="login-button-section">
        <button v-if="!loggedIn" class="login-button" @click="login">Login</button>
        <button v-else-if="!authenticated" class="login-button" @click="verify">Verify Code</button>
      </div>
    </div>
  </div>
  <div class="container">
    <TheMenu />
    <ViewBox>
      <ShopProducts />
    </ViewBox>
  </div>
</template>

<script>
import TheMenu from './components/TheMenu';
import ViewBox from "./components/ViewBox";
import ShopProducts from "./pages/ShopProducts";
import anime from 'animejs';

export default {
  name: 'App',
  components: {
    ShopProducts,
    TheMenu,
    ViewBox
  },
  data() {
    return{
      loggedIn: false,
      authenticated: false
    }
  },
  methods: {
    login(){
      this.loggedIn = true;
      anime({
        targets: '.credentials-animate',
        translateX: [0, -600],
        easing: 'easeOutQuad',
        duration: 500
      });
    },
    verify(){
      this.authenticated = true;
    },
    logout(){
      this.loggedIn = false;
    }
  }
}
</script>

<style lang="scss">
#app{
  height: 100%;
}
body{
  background-color: $revir-off-white;
}
.container{
  height: 100%;
  width: 100%;
  display: flex;
}
.login-screen{
  background-color: $pastel-blue;
  height: 100%;
  width: 100%;
  z-index: 999;
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;

  .login-box{
    background-color: $revir-white;
    border-radius: 20px;
    width: 600px;
    height: 460px;
    position: relative;
    overflow: hidden;
    font-size: 18px;

    .login-box-welcome{
      color: rgb(51, 65, 85);
      font-size: 40px;
      font-weight: bold;
      margin-top: 10px;
      text-align: center;
    }
    .login-box-factor{
      color: rgb(51, 65, 85);
      font-size: 30px;
      font-weight: bold;
      margin-top: 10px;
      text-align: center;
    }
    hr{
      background-color: #aaa;
      border: none;
      height: 2px;
      margin: 4px auto 40px auto;
      width: 20%;
    }
    input{
      border: 1px solid #aaa;
      border-radius: 10px;
      color: $login-gray;
      display: inline-block;
      font-size: 18px;
      margin: 10px 100px;
      width: 400px;
      padding: 10px;
    }
    label{
      display: inline-block;
      margin-left: 100px;
      width: 400px;
      color: $login-gray;
    }
    .login-button-section{
      background-color: rgb(51, 65, 85);
      bottom: 0;
      position: absolute;
      height: 80px;
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: flex-end;
      .login-button{
        background-color: $pastel-blue;
        border: none;
        border-radius: 4px;
        color: $revir-white;
        cursor: pointer;
        font-size: 16px;
        padding: 10px 20px;
        margin-right: 20px;
        &:hover{
          cursor: pointer;
        }
      }
    }
    .credentials-animate{
      width: 1200px;
      display: flex;
    }
    .credentials-login{
      width: 600px;
      display: flex;
      flex-direction: column;
    }
    .credentials-authenticate{
      width: 600px;
      height: 180px;
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      justify-content: center;
    }
  }
}
.or-sign-in-with{
  color: rgba(20, 20, 20, 0.75);
  margin-top: 10px;
  margin-left: 100px;
  margin-bottom: 10px;
}
.single-sign-on{
  margin-left: 100px;
  width: 400px;
  display: flex;
  align-items: center;
  justify-content: space-evenly;

  img{
    //background-color: lighten($pastel-blue, 40%);
    border: 2px solid lighten($revir-black, 60%);
    border-radius: 10px;
    padding: 10px;
    width: 50px;
  }
}
</style>
