<template>
  <div class="shopproducts-container">
    <div class="modal-added-to-cart">
      <p>Added to cart</p>
    </div>
    <h1 v-if="isProductsPage">Products</h1>
    <h1 v-else-if="isCartPage">Cart</h1>
    <h1 v-else-if="isCheckoutPage">Checkout</h1>
    <div v-if="isProductsPage" class="cart-total" @click="cartClicked">
      <p>Cart {{ getCartLength }}</p>
      <img class="cart" src="../assets/shopping-cart-solid.svg" alt="Shopping Cart">
    </div>
    <div v-else-if="isCartPage || isCheckoutPage" class="view-products" @click="viewProducts">
      <p>View All Products</p>
    </div>

    <div v-if="isProductsPage" class="products">
      <ProductItem @add-to-cart="addedToCart" id="1" name="HCI Member Handbook" description="(English)" image="HCI-Member-Handbook.jpg"/>
      <ProductItem @add-to-cart="addedToCart" id="2" name="HCI Member Handbook" description="(Russian)" image="HCI-Member-Handbook-RUS.jpg"/>
      <ProductItem @add-to-cart="addedToCart" id="3" name="HCI Member Handbook" description="(Spanish)" image="HCI-Member-Handbook-SPA.jpg"/>
      <ProductItem @add-to-cart="addedToCart" id="4" name="HCI Provider Directory" description="(English)" image="HCI-Provider-Directory.jpg"/>
      <ProductItem @add-to-cart="addedToCart" id="5" name="HCI Welcome Kit" description="(English)" image="HCI-Welcome-Kit.jpg"/>
      <ProductItem @add-to-cart="addedToCart" id="6" name="HealthChoice MLTSS" description="(Illinois)" image="HealthChoice-Illinois-MLTSS.png"/>
      <ProductItem @add-to-cart="addedToCart" id="7" name="IL PHI Auth Form Populated" description="(Arabic)" image="IL-PHI-Auth-Form-Populated-ARABIC.jpg"/>
      <ProductItem @add-to-cart="addedToCart" id="8" name="IL PHI Auth Form Populated" description="(English)" image="IL-PHI-Auth-Form-Populated-ENG.jpg"/>
      <ProductItem @add-to-cart="addedToCart" id="9" name="IL PHI Auth Form Populated" description="(Russian)" image="IL-PHI-Auth-Form-Populated-RUS.jpg"/>
      <ProductItem @add-to-cart="addedToCart" id="10" name="IL PHI Auth Form Populated" description="(Spanish)" image="IL-PHI-Auth-Form-Populated-SPA.jpg"/>
      <ProductItem @add-to-cart="addedToCart" id="11" name="MLTSS Provider Directory" description="(English)" image="HCI-Member-Handbook.jpg"/>
      <ProductItem @add-to-cart="addedToCart" id="12" name="MLTSS Welcome Kit" description="(English)" image="MLTSS-Welcome-Kit.jpg"/>
      <ProductItem @add-to-cart="addedToCart" id="13" name="Request for Member Access" description="Member Access to PHI Form" image="Request-for-Member-Access-to-PHI-Form.jpg"/>
      <ProductItem @add-to-cart="addedToCart" id="14" name="WVR Welcome Kit" description="(English)" image="WVR-Welcome-Kit.jpg"/>

    </div>
    <div v-else-if="isCartPage" class="products">
      <div class="item-in-cart" v-for="(item, index) in cartItems" :key="item.id">
        <img :src="require(`../assets/products/${item.img}`)" alt="Paper Example">
        <div class="title-description">
          <p>{{ item.name }}</p>
          <p>{{ item.description }}</p>
        </div>
        <div class="quantity">
          <p class="quantity-add" @click="addQuantity(1, index)">
            <span>+</span>
          </p>
          <input @keyup="changeInputQuantity($event, index)" :value="item.quantity" min="0">
          <p class="quantity-sub" @click="addQuantity(-1, index)">
            <span>-</span>
          </p>
        </div>
        <p @click="removeItem(index)" class="remove">Remove</p>
      </div>
      <p v-if="getCartLength == 0" class="addItemToCart">Your cart is currently empty.</p>
      <div class="checkout-container">
        <button v-if="getCartLength != 0" @click="checkout">Checkout</button>
      </div>
    </div>
    <div v-else-if="isCheckoutPage" class="products">
      <div class="checkout-info">
        <p class="title">Shipping Information</p>
        <button class="back-to-cart" @click="cartClicked">Back To Cart</button>
        <label for="customerName">Full Name*</label>
        <input type="text">
        <label for="customerEmail">Email Address</label>
        <input type="email">
        <label for="customerCompany">Company</label>
        <input type="text">
        <label for="customerAddressOne">Address Line 1*</label>
        <input type="text">
        <label for="customerAddressTwo">Address Line 2</label>
        <input type="text">
        <label for="customerAddressThree">Address Line 3</label>
        <input type="text">
        <label for="customerCity">City*</label>
        <input type="text">
        <label for="customerState">State*</label>
        <input type="text">
        <label for="customerCountry">Country*</label>
        <input type="text">
        <label for="customerPhone">Phone Number</label>
        <input type="text">
        <label for="customerShippingMethod">Shipping Method</label>
        <select name="" id="">
          <option value="standard">Standard Shipping (Ground)</option>
          <option value="twoDay">2 Day Shipping</option>
          <option value="priority">Priority Shipping</option>
        </select>
        <button @click="placeOrder" class="truck-button">
          <span class="default">Complete Order</span>
          <span class="success">
        Order Placed
        <svg viewbox="0 0 12 10">
            <polyline points="1.5 6 4.5 9 10.5 1"></polyline>
        </svg>
    </span>
          <div class="truck">
            <div class="wheel"></div>
            <div class="back"></div>
            <div class="front"></div>
            <div class="box"></div>
          </div>
        </button>
<!--        <button @click="placeOrder" class="place-order">Place Order</button>&ndash;&gt;-->
      </div>
    </div>
    <div class="loading-order" v-if="isLoading">
      <p>Placing Your Order...</p>
<!--      <div class="loading-spinner"></div>-->
    </div>
  </div>
</template>

<script>
import anime from 'animejs';
import _ from 'lodash';
import ProductItem from "../components/ProductItem";
export default {
  name: "ShopProducts",
  emits: ['increment'],
  components: {ProductItem},
  data(){
    return{
      mode: "products", //possible modes "products", "cart", "checkout", "loading"
      cartItems: [
        // {
        //   'id': 1,
        //   'img': 'example.jpeg',
        //   'name': "Name of Item",
        //   'description': 'Description of Item',
        //   'quantity': 1
        // }
      ]
    }
  },
  computed: {
    isProductsPage(){
      return this.mode === "products" ? true : false;
    },
    isCartPage(){
      return this.mode === "cart" ? true : false;
    },
    isCheckoutPage(){
      return this.mode === "checkout" ? true : false;
    },
    isLoading(){
      return this.mode === "loading" ? true : false;
    },
    getCartLength(){
      return this.cartItems.length;
    }
  },
  methods: {
    cartClicked(){
      // A cool button press effect.
      // anime({
      //   targets: '.cart-total',
      //   boxShadow: '8px 8px 12px 0 rgba(0, 0, 0, 0.25),\n' +
      //       '-8px -8px 12px 0 rgba(255, 255, 255, 0.3),\n' +
      //       'inset 8px 8px 12px 0 rgba(0, 0, 0, 0.25),\n' +
      //       'inset  -8px -8px 12px 0 rgba(255, 255, 255, 0.3)',
      //   easing: "linear",
      //   duration: 0
      // });
      this.mode = "cart";
    },
    viewProducts(){
      this.mode = "products";
    },
    addedToCart(id, name, description, image){
      console.log(id, name, description, image);
      this.cartItems.push({
        id,
        img: image,
        name,
        description,
        quantity: 1
      });
      this.cartItems = _.uniqBy(this.cartItems, 'id');
      anime({
        targets: '.modal-added-to-cart',
        translateY: [-100, -8],
        easing: 'spring(1, 100, 15, 10)',
        direction: 'alternate',
        duration: 1000,
        loop: 1
      });
    },
    addQuantity(amount = 1 , index){
      this.cartItems[index].quantity += amount;
      if(this.cartItems[index].quantity <= 0){
        this.cartItems[index].quantity = 1;
      }
    },
    changeInputQuantity(e, index){
      console.log(e.target.value);
      let quantity = _.round(_.toNumber(e.target.value), 0);
      console.log(index);
      this.cartItems[index].quantity = quantity;
    },
    removeItem(index){
      this.cartItems.splice(index, 1);
    },
    checkout(){
      this.mode = "checkout";
    },
    placeOrder(){
      this.mode = "loading";
      anime({
        targets: '.loading-spinner',
        translateX: 250,
        duration: 3000
      });
      setTimeout(()=>{
        this.mode = "products";
        this.cartItems = [];
      }, 2000);

      //kyles animation
      // document.querySelectorAll('.truck-button').forEach(button => {
      //   button.addEventListener('click', e => {
      //
      //     e.preventDefault();
      //
      //     let box = button.querySelector('.box'),
      //         truck = button.querySelector('.truck');
      //
      //     if(!button.classList.contains('done')) {
      //
      //       if(!button.classList.contains('animation')) {
      //
      //         button.classList.add('animation');
      //
      //         gsap.to(button, {
      //           '--box-s': 1,
      //           '--box-o': 1,
      //           duration: .3,
      //           delay: .5
      //         });
      //
      //         gsap.to(box, {
      //           x: 0,
      //           duration: .4,
      //           delay: .7
      //         });
      //
      //         gsap.to(button, {
      //           '--hx': -5,
      //           '--bx': 50,
      //           duration: .18,
      //           delay: .92
      //         });
      //
      //         gsap.to(box, {
      //           y: 0,
      //           duration: .1,
      //           delay: 1.15
      //         });
      //
      //         gsap.set(button, {
      //           '--truck-y': 0,
      //           '--truck-y-n': -26
      //         });
      //
      //         gsap.to(button, {
      //           '--truck-y': 1,
      //           '--truck-y-n': -25,
      //           duration: .2,
      //           delay: 1.25,
      //           onComplete() {
      //             gsap.timeline({
      //               onComplete() {
      //                 button.classList.add('done');
      //               }
      //             }).to(truck, {
      //               x: 0,
      //               duration: .4
      //             }).to(truck, {
      //               x: 40,
      //               duration: 1
      //             }).to(truck, {
      //               x: 20,
      //               duration: .6
      //             }).to(truck, {
      //               x: 96,
      //               duration: .4
      //             });
      //             gsap.to(button, {
      //               '--progress': 1,
      //               duration: 2.4,
      //               ease: "power2.in"
      //             });
      //           }
      //         });
      //
      //       }
      //
      //     } else {
      //       button.classList.remove('animation', 'done');
      //       gsap.set(truck, {
      //         x: 4
      //       });
      //       gsap.set(button, {
      //         '--progress': 0,
      //         '--hx': 0,
      //         '--bx': 0,
      //         '--box-s': .5,
      //         '--box-o': 0,
      //         '--truck-y': 0,
      //         '--truck-y-n': -26
      //       });
      //       gsap.set(box, {
      //         x: -24,
      //         y: -6
      //       });
      //     }
      //
      //   });
      // });

    }
  }
}
</script>

<style scoped lang="scss">
.shopproducts-container{
  color: $revir-black;
  width: 100%;
  height: 100%;
  overflow-y: hidden;
}
h1{
  margin: 28px 46px;
}
.products{
  border-top: 4px solid $revir-black;
  margin-top: 30px;
  height: 100%;
  padding-bottom: 100px;
  overflow-y: auto;
  //display: flex;
  //flex-wrap: wrap;
  ////@TODO behave when u expand
  //align-items: center;
  //justify-content: center;
}
.cart-total, .view-products{
  cursor: pointer;
  font-size: 20px;
  position: absolute;
  top: 20px;
  right: 20px;
  width: 120px;
  display: flex;
  align-items: center;
  justify-content: space-evenly;

  //neumorphism
  background: $revir-off-white;
  box-shadow: 8px 8px 12px 0 rgba(0, 0, 0, 0.25),
  -8px -8px 12px 0 rgba(255, 255, 255, 0.3);
  padding: 10px;
  border-radius: 60px;
  & > .cart{
    width: 30px;
  }
}
.view-products{
  font-weight: bold;
  width: 200px;
  height: 46.66px;
}
.modal-added-to-cart{
  background-color: $pastel-blue;
  border-bottom-left-radius: 20px;
  border-bottom-right-radius: 20px;
  color: $revir-white;
  text-transform: capitalize;
  font-weight: bold;
  padding: 14px 10px 10px 10px;
  position: absolute;
  left: 50%;
  width: 200px;
  transform: translateY(-100%);
  p{
    text-align: center;
    font-size: 20px;
  }
}
.item-in-cart{
  background: linear-gradient(-45deg, rgba(210, 210, 210, 0.22), rgba(200, 200, 200, 0.25));
  box-shadow: 8px 8px 12px 0 rgba(0, 0, 0, 0.25),
  -8px -8px 12px 0 rgba(255, 255, 255, 0.3);
  border-radius: 20px;
  margin: 20px auto;
  font-size: 20px;
  padding: 0 20px;
  width: 700px;
  height: 100px;

  display: flex;
  align-items: center;
  justify-content: space-between;

  img{
    height: 80%;
  }
  .quantity{
    width: 200px;
    display: flex;
    align-items: center;
    justify-content: center;
    .quantity-add,
    .quantity-sub{
      border-radius: 50%;
      color: $revir-white;
      font-size: 20px;
      font-weight: bold;
      line-height: 10px;
      width: 38px;
      height: 38px;

      display: flex;
      align-items: center;
      justify-content: center;
      
      &:hover{
        cursor: pointer;
      }
    }
    .quantity-add{
      background-color: $revir-green;
    }
    .quantity-sub{
      background-color: $revir-red;
    }
    p{
      margin: 10px;
    }
    input{
      border: none;
      border-radius: 10px;
      font-size: 20px;
      text-align: center;
      outline: none;
      width: 60px;
      height: 38px;
    }
  }
  .title-description{
    width: 300px;
    p:nth-child(1){
      font-weight: bold;
    }
  }
  .remove{
    color: $revir-red;
    &:hover{
      cursor: pointer;
    }
  }
}
.checkout-container{
  background: rgba(0,0,0,0);
  border-radius: 20px;
  margin: 20px auto;
  font-size: 20px;
  padding: 0 20px;
  width: 700px;
  height: 40px;

  display: flex;
  align-items: center;
  justify-content: flex-end;

  button{
    font-size: 20px;
    background: linear-gradient(-45deg, darken($revir-green, 10%), darken($revir-green, 20%));
    box-shadow: 8px 8px 12px 0 rgba(0, 0, 0, 0.25),
    -8px -8px 12px 0 rgba(255, 255, 255, 0.3);
    border: none;
    border-radius: 10px;
    color: $revir-white;
    cursor: pointer;
    padding: 10px;
  }
}
.checkout-info{
  background: linear-gradient(-45deg, rgba(210, 210, 210, 0.22), rgba(200, 200, 200, 0.25));
  box-shadow: 8px 8px 12px 0 rgba(0, 0, 0, 0.25),
  -8px -8px 12px 0 rgba(255, 255, 255, 0.3);
  border-radius: 20px;
  position: relative;
  margin: 30px auto;
  padding: 40px;
  width: 80%;

  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-evenly;

  .title{
    font-size: 30px;
    text-align: center;
    width: 100%;
  }
  .back-to-cart{
    border: none;
    background: $pastel-blue;
    box-shadow: 8px 8px 12px 0 rgba(0, 0, 0, 0.25),
    -8px -8px 12px 0 rgba(255, 255, 255, 0.3);
    border-radius: 20px;
    color: $revir-white;
    cursor: pointer;
    padding: 10px 30px;
    position: absolute;
    top: 0;
    right: 0;
    margin: 40px;
    font-size: 16px;
    font-weight: bold;
  }
  input,
  select{
    border: 1px solid #aaa;
    border-radius: 10px;
    color: $login-gray;
    display: inline-block;
    font-size: 18px;
    margin: 10px 0;
    width: 400px;
    padding: 10px;
  }
  label{
    display: inline-block;
    margin: 10px 0 0 0;
    width: 400px;
    color: $login-gray;
  }
  .place-order{
    border: none;
    background: $revir-green;
    box-shadow: 8px 8px 12px 0 rgba(0, 0, 0, 0.25),
    -8px -8px 12px 0 rgba(255, 255, 255, 0.3);
    border-radius: 20px;
    color: $revir-white;
    cursor: pointer;
    margin-top: 10px;
    padding: 10px 30px;
    font-size: 16px;
    font-weight: bold;
  }
}
.loading-order{
  background-color: rgba(0,0,0,0.8);
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 9999;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  p{
    font-size: 30px;
    color: $revir-white;
    margin-bottom: 20px;
  }
  .loading-spinner{
    border: 10px solid #f3f3f3;
    border-radius: 50%;
    border-top: 10px solid rgba(0,0,0,0);
    width: 100px;
    height: 100px;
  }
}
.addItemToCart{
  font-size: 30px;
  margin-top: 10px;
  text-align: center;
}

//Kyles Animation
.truck-button {
  --color: #fff;
  --background: #2B3044;
  --tick: #16BF78;
  --base: #0D0F18;
  --wheel: #2B3044;
  --wheel-inner: #646B8C;
  --wheel-dot: #fff;
  --back: #6D58FF;
  --back-inner: #362A89;
  --back-inner-shadow: #2D246B;
  --front: #A6ACCD;
  --front-shadow: #535A79;
  --front-light: #FFF8B1;
  --window: #2B3044;
  --window-shadow: #404660;
  --street: #646B8C;
  --street-fill: #404660;
  --box: #DCB97A;
  --box-shadow: #B89B66;
  padding: 12px 0;
  width: 172px;
  cursor: pointer;
  text-align: center;
  position: relative;
  border: none;
  outline: none;
  color: var(--color);
  background: var(--background);
  border-radius: var(--br, 5px);
  -webkit-appearance: none;
  -webkit-tap-highlight-color: transparent;
  transform-style: preserve-3d;
  transform: rotateX(var(--rx, 0deg)) translateZ(0);
  transition: transform .5s, border-radius .3s linear var(--br-d, 0s);
  &:before,
  &:after {
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 6px;
    display: block;
    background: var(--b, var(--street));
    transform-origin: 0 100%;
    transform: rotateX(90deg) scaleX(var(--sy, 1));
  }
  &:after {
    --sy: var(--progress, 0);
    --b: var(--street-fill);
  }
  .default,
  .success {
    display: block;
    font-weight: 500;
    font-size: 14px;
    line-height: 24px;
    opacity: var(--o, 1);
    transition: opacity .3s;
  }
  .success {
    --o: 0;
    position: absolute;
    top: 12px;
    left: 0;
    right: 0;
    svg {
      width: 12px;
      height: 10px;
      display: inline-block;
      vertical-align: top;
      fill: none;
      margin: 7px 0 0 4px;
      stroke: var(--tick);
      stroke-width: 2;
      stroke-linecap: round;
      stroke-linejoin: round;
      stroke-dasharray: 16px;
      stroke-dashoffset: var(--offset, 16px);
      transition: stroke-dashoffset .4s ease .45s;
    }
  }
  .truck {
    position: absolute;
    width: 72px;
    height: 28px;
    transform: rotateX(90deg) translate3d(var(--truck-x, 4px), calc(var(--truck-y-n, -26) * 1px), 12px);
    &:before,
    &:after {
      content: '';
      position: absolute;
      bottom: -6px;
      left: var(--l, 18px);
      width: 10px;
      height: 10px;
      border-radius: 50%;
      z-index: 2;
      box-shadow: inset 0 0 0 2px var(--wheel), inset 0 0 0 4px var(--wheel-inner);
      background: var(--wheel-dot);
      transform: translateY(calc(var(--truck-y) * -1px)) translateZ(0);
    }
    &:after {
      --l: 54px;
    }
    .wheel,
    .wheel:before {
      position: absolute;
      bottom: var(--b, -6px);
      left: var(--l, 6px);
      width: 10px;
      height: 10px;
      border-radius: 50%;
      background: var(--wheel);
      transform: translateZ(0);
    }
    .wheel {
      transform: translateY(calc(var(--truck-y) * -1px)) translateZ(0);
    }
    .wheel:before {
      --l: 35px;
      --b: 0;
      content: '';
    }
    .front,
    .back,
    .box {
      position: absolute;
    }
    .back {
      left: 0;
      bottom: 0;
      z-index: 1;
      width: 47px;
      height: 28px;
      border-radius: 1px 1px 0 0;
      background: linear-gradient(68deg, var(--back-inner) 0%, var(--back-inner) 22%, var(--back-inner-shadow) 22.1%, var(--back-inner-shadow) 100%);
      &:before,
      &:after {
        content: '';
        position: absolute;
      }
      &:before {
        left: 11px;
        top: 0;
        right: 0;
        bottom: 0;
        z-index: 2;
        border-radius: 0 1px 0 0;
        background: var(--back);
      }
      &:after {
        border-radius: 1px;
        width: 73px;
        height: 2px;
        left: -1px;
        bottom: -2px;
        background: var(--base);
      }
    }
    .front {
      left: 47px;
      bottom: -1px;
      height: 22px;
      width: 24px;
      -webkit-clip-path: polygon(55% 0, 72% 44%, 100% 58%, 100% 100%, 0 100%, 0 0);
      clip-path: polygon(55% 0, 72% 44%, 100% 58%, 100% 100%, 0 100%, 0 0);
      background: linear-gradient(84deg, var(--front-shadow) 0%, var(--front-shadow) 10%, var(--front) 12%, var(--front) 100%);
      &:before,
      &:after {
        content: '';
        position: absolute;
      }
      &:before {
        width: 7px;
        height: 8px;
        background: #fff;
        left: 7px;
        top: 2px;
        -webkit-clip-path: polygon(0 0, 60% 0%, 100% 100%, 0% 100%);
        clip-path: polygon(0 0, 60% 0%, 100% 100%, 0% 100%);
        background: linear-gradient(59deg, var(--window) 0%, var(--window) 57%, var(--window-shadow) 55%, var(--window-shadow) 100%)
      }
      &:after {
        width: 3px;
        height: 2px;
        right: 0;
        bottom: 3px;
        background: var(--front-light);
      }
    }
    .box {
      width: 13px;
      height: 13px;
      right: 56px;
      bottom: 0;
      z-index: 1;
      border-radius: 1px;
      overflow: hidden;
      transform: translate(calc(var(--box-x, -24) * 1px), calc(var(--box-y, -6) * 1px)) scale(var(--box-s, .5));
      opacity: var(--box-o, 0);
      background: linear-gradient(68deg, var(--box) 0%, var(--box) 50%, var(--box-shadow) 50.2%, var(--box-shadow) 100%);
      background-size: 250% 100%;
      background-position-x: calc(var(--bx, 0) * 1%);
      &:before,
      &:after {
        content: '';
        position: absolute;
      }
      &:before {
        content: '';
        background: rgba(white, .2);
        left: 0;
        right: 0;
        top: 6px;
        height: 1px;
      }
      &:after {
        width: 6px;
        left: 100%;
        top: 0;
        bottom: 0;
        background: var(--back);
        transform: translateX(calc(var(--hx, 0) * 1px));
      }
    }
  }
  &.animation {
    --rx: -90deg;
    --br: 0;
    .default {
      --o: 0;
    }
    &.done {
      --rx: 0deg;
      --br: 5px;
      --br-d: .2s;
      .success {
        --o: 1;
        --offset: 0;
      }
    }
  }
}

html {
  box-sizing: border-box;
  -webkit-font-smoothing: antialiased;
}

* {
  box-sizing: inherit;
  &:before,
  &:after {
    box-sizing: inherit;
  }
}

// Center & dribbble
body {
  min-height: 100vh;
  display: flex;
  font-family: 'Inter UI', 'Inter', Arial;
  justify-content: center;
  align-items: center;
  background: #ECEFFC;
  .dribbble {
    position: fixed;
    display: block;
    right: 20px;
    bottom: 20px;
    img {
      display: block;
      height: 28px;
    }
  }
  .twitter {
    position: fixed;
    display: block;
    right: 64px;
    bottom: 14px;
    svg {
      width: 32px;
      height: 32px;
      fill: #1da1f2;
    }
  }
}
</style>