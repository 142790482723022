<template>
<div class="viewbox-container">
  <slot></slot>
</div>
</template>

<script>
export default {
  name: "ViewBox"
}
</script>

<style scoped lang="scss">
.viewbox-container{
  background-color: $revir-off-white;
  width: 100%;
}
</style>