<template>
  <div class="item">
    <img :src="require(`@/assets/products/${image}`)" alt="Example Product">
    <p>{{ name }}</p>
    <p>{{ description }}</p>
    <div @click="addToCart" class="add-to-cart">
      <img src="../assets/cart-plus-solid.svg" alt="Add To Cart Button">
    </div>
  </div>
</template>

<script>
export default {
  name: "ProductItem",
  props: [ 'id', 'name', 'description', 'image'],
  methods: {
    addToCart(){
      this.$emit('addToCart', this.id, this.name, this.description, this.image);
    }
  }
}
</script>

<style scoped lang="scss">
.item {
  display: inline-block;
  position: relative;
  width: 300px;
  height: 360px;
  margin: 20px;
  background: $revir-off-white;
  //box-shadow: 12px 12px 16px 0 rgba(0, 0, 0, 0.25),
  //-8px -8px 12px 0 rgba(255, 255, 255, 0.3);
  box-shadow: 8px 8px 12px 0 rgba(0, 0, 0, 0.25),
  -8px -8px 12px 0 rgba(255, 255, 255, 0.3);
  border-radius: 10px;

  display: inline-flex;
  align-items: center;
  justify-content: flex-start;
  flex-direction: column;

  & > img {
    margin-top: 20px;
    height: 240px;
  }

  & > p {
    text-align: left;
    margin-left: 20px;
    font-size: 17px;
    font-weight: bold;
    width: 100%;

    &:nth-child(2) {
      margin-top: 40px;
    }

    &:nth-child(3) {
      font-weight: normal;
    }
  }
}

.add-to-cart {
  background: linear-gradient(-45deg, rgba($revir-green, 1), rgba(#00a300, 1));
  border-radius: 100px;
  position: absolute;
  bottom: 10px;
  right: 10px;
  width: 50px;
  height: 50px;
  padding: 10px;
  display: flex;
  align-items: center;
  justify-content: center;

  &:hover {
    cursor: pointer;
  }
}
</style>