<template>
<div class="menu">
  <img @click="spin" class="arrow" src="../assets/arrow-left-solid.svg" alt="Arrow">
  <div v-show="menuIsOpen"  class="logo hide">
    <img src="../assets/MolinaLogo.jpg" alt="Company Logo">
  </div>
<!--  <a v-show="menuIsOpen"  class="hide">Home</a>-->
  <a v-show="menuIsOpen"  class="highlighted hide">Catalog</a>
  <a v-show="menuIsOpen"  class="hide">File Manager</a>
  <a v-show="menuIsOpen"  class="logout hide" @click="refreshPage">Logout</a>
</div>
</template>

<script>
import anime from 'animejs';
export default {
  name: "TheHome",
  data() {
    return{
      menuIsOpen: true
    }
  },
  methods: {
    spin(){
      let turnDegree = this.menuIsOpen ? '180' : '0';
      let width = this.menuIsOpen ? '50px' : '300px';
      anime({
        targets: '.arrow',
        rotate: turnDegree,
        easing: 'cubicBezier(0.105, 0.780, 0.355, 0.910)',
        duration: '200'
      });
      anime({
        targets: '.menu',
        width: width,
        easing: 'cubicBezier(0.105, 0.780, 0.355, 0.910)',
        duration: '200'

      });
      this.menuIsOpen = !this.menuIsOpen;
    },
    refreshPage(){
      location.reload();
    }
  }
}
</script>

<style scoped lang="scss">
.menu{
  //background-color: $main-color;
  background-color: #1aa2a0;
  border-top-right-radius: 24px;
  border-bottom-right-radius: 24px;
  position: relative;
  width: 300px;
  height: 100%;

  display: flex;
  flex-direction: column;
  align-items: center;
}
.logo{
  background-color: $revir-white;
  border-radius: 50%;
  margin-top: 30px;
  margin-bottom: 30px;
  height: 180px;
  width: 180px;

  display: flex;
  align-items: center;
  justify-content: center;

  & > img{
    width: 100px;
  }
}
a{
  color: $revir-white;
  cursor: pointer;
  display: block;
  align-self: flex-start;
  font-size: 20px;
  width: 90%;
  margin: 4px 0;
  text-align: center;
  padding: 10px 0 10px 30px;
  border-top-right-radius: 20px;
  border-bottom-right-radius: 20px;
  &:hover{
    cursor: pointer;
  }
  &.highlighted{
    //background-color: rgba(147, 147, 147, 0.6);
    background-color: darken(#1aa2a0, 20%);
  }
}
.arrow{
  position: absolute;
  top: 10px;
  right: 10px;
  width: 30px;
  &:hover{
    cursor: pointer;
  }
}
.logout{
  background-color: $revir-red;
  border-radius: 20px;
  padding: 10px 15px;
  margin-left: 10px;
  bottom: 10px;
  position: absolute;
}
</style>